import React from 'react'
import { graphql, Link } from 'gatsby'
import _ from 'lodash'

import { List, ListItem, ListItemText, Typography, Paper, withStyles } from '@material-ui/core'

import Layout from '../components/layout/Layout'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  topBanner: {
    paddingTop: '70px',
    background: 'linear-gradient(65deg, #198CFF 0%, #01EEFD 100%)',
    position: 'relative'
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
})

// function mapGroup (group) {
//   if (group.totalCount > 1) {
//     const indexPageNode = group.edges.find(edge => edge.node.frontmatter.page_type === 'index')
//     const sectionPageNodes = group.edges
//       .filter(edge => edge.node.frontmatter.page_type !== 'index')
//       .map(edge => {
//         return (
//           <ListItem>
//             <ListItemText>
//               <Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
//             </ListItemText>
//           </ListItem>
//         )
//       })
//     return (
//       <>
//         <ListItem>
//           <ListItemText>
//             <Link to={indexPageNode.node.frontmatter.path}>{indexPageNode.node.frontmatter.title}</Link>
//           </ListItemText>
//         </ListItem>
//         <List>
//           {sectionPageNodes}
//         </List>
//       </>
//     )
//   }
//   const pageNode = group.edges[0].node
//   return (
//     <ListItem>
//       <ListItemText>
//         <Link to={pageNode.frontmatter.path}>{pageNode.frontmatter.title}</Link>
//       </ListItemText>
//     </ListItem>
//   )
// }

function PageSiteMap (props) {
  const {
    classes,
    data: {
      allStrapiWebpage
    }
  } = props

  let pagesList = []
  for (let edge of allStrapiWebpage.edges) {
    const node = edge.node
    // If node.webSection === undefined
    if (_.isNil(node.webSection)) {
      // No section
      pagesList.push(
        <ListItem>
          <ListItemText>
            <Link to={node.path}>{node.label}</Link>
          </ListItemText>
        </ListItem>
      )
      continue
    }
    if (node.pageType === 'Index') {
      // If node.pageType === 'Index'
      // Filter array on same section
      pagesList.push(
        <>
          <ListItem>
            <ListItemText>
              <Link to={node.path}>{node.label}</Link>
            </ListItemText>
          </ListItem>
          <List>
            {
              allStrapiWebpage.edges
                .filter(e => e.node.webSection && e.node.webSection.id === node.webSection.id && e.node.pageType === 'Page')
                .map(e => (
                  <ListItem>
                    <ListItemText>
                      <Link to={e.node.path}>{e.node.label}</Link>
                    </ListItemText>
                  </ListItem>
                ))
            }
          </List>
        </>
      )
    }
  }

  // const pageListItems = props.data.allMarkdownRemark.group
  //   // BUG: sort in code as GraphQL doesn't support mapping in sorting
  //   .sort((a, b) => {
  //     const indexA = a.edges[0].node.frontmatter.section.index
  //     const indexB = b.edges[0].node.frontmatter.section.index
  //     return (indexA < indexB ? -1 : 1)
  //   })
  //   // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
  //   .map(group => mapGroup(group))

  return (
    <Layout title='Plan du site' description='Plan du site Phileass.' pathname={props.location.pathname}>
      <div className={classes.topBanner} />
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={1}>
          <Typography component='h1' variant='h1'>
            Plan du site
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                <Link to='devis'>Devis</Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link to='contact'>Contact</Link>
              </ListItemText>
            </ListItem>
            {pagesList}
          </List>
        </Paper>
      </div>
    </Layout>
  )
}

export default withStyles(styles)(PageSiteMap)

export const query = graphql`
  query {
    allStrapiWebpage(
      sort: {
        order: ASC,
        fields: [webSection___sortIndex, sortIndex, label]
      }) {
      edges {
        node {
          id
          sortIndex
          label
          path
          pageType
          webSection {
            id
            sortIndex
            name
            label
          }
        }
      }
    }
  }
`
